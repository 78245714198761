
class ImageCards {
    constructor(containerSelector) {
      this.parent = document.querySelector(containerSelector);
      this.items = this.parent.children;
      this.currentIndex = 0;
      // temp
      //[...this.items].map((item) => item.classList.add('ready'));
      // this.forward();
    }
  
    change(targetIndex) {
      // ->
      if (targetIndex > this.currentIndex) {
        this.forward();
        if (targetIndex > this.currentIndex + 1) {
          setTimeout(() => {
            this.forward();
          }, 1000);
        }
      } else {
        // <-
        this.backward();
        if (targetIndex < this.currentIndex - 1) {
          setTimeout(() => {
            this.backward();
          }, 1000);
        }
      }
      this.currentIndex = targetIndex;
    }
  
    forward() {
      this.items[0].classList.remove('ready');
      setTimeout(() => {
        var firstItemDetached = this.items[0].parentNode.removeChild(this.items[0]);
        this.parent.appendChild(firstItemDetached);
        setTimeout(() => {
          this.items[this.items.length - 1].classList.add('ready');
          adaptImageOnBackground();
        }, 50);
      }, 400);
    }
  
    backward() {
      var i = this.items.length - 1;
      this.items[i].classList.remove('ready');
      setTimeout(() => {
        var lastItemDetached = this.items[i].parentNode.removeChild(
          this.items[i]
        );
        this.parent.insertBefore(lastItemDetached, this.parent.firstChild);
        setTimeout(() => {
          this.items[0].classList.add('ready');
          adaptImageOnBackground();
        }, 50);
      }, 400);
    }
  }
  